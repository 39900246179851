<script setup>
const props = defineProps(["product"]);
const swiper = ref(null);
const swiperControl = ref(null);
const setThumbsSwiper = (swiper) => {
  swiperControl.value = swiper;
};
</script>
<template>
  <div class="product-gallery-wrap">
    <Swiper
        dir="rtl"
        class="product-gallery"
        ref="swiper"
        :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
        :thumbs="{ swiper: swiperControl }"
        :modules="[SwiperNavigation, SwiperThumbs]"
    >
      <SwiperSlide
          class="product-gallery__item"
          :lazy="false"
          v-for="(image, index) in [product?.featuredImage?.node]"
          :key="index"
      >
        <span class="image_title">{{ image?.title }}</span>
        <wpImage :img="image"/>
      </SwiperSlide>
      <SwiperSlide
          class="product-gallery__item"
          v-for="(image, index) in product?.galleryImages?.nodes"
          :key="index + 1"
      >
        <span class="image_title">{{ image?.title }}</span>
        <wpImage :img="image"/>
      </SwiperSlide>
      <div class="swiper-button-prev arr-prev">
        <nuxt-img src="/images/icons/right-arrow.png" alt="אייקון תמונה הקודמת"/>
      </div>
      <div class="swiper-button-next arr-next">
        <nuxt-img src="/images/icons/left-arrow.png" alt="אייקון תמונה הבאה"/>
      </div>
    </Swiper>
  </div>
  <div class="product-gallery-thumbs_wrap relative px-4">
    <Swiper
        ref="swiperControl"
        class="gallery-thumbs"
        dir="rtl"
        :slides-per-view="5"
        @swiper="setThumbsSwiper"
        :navigation="{
        nextEl: '.arr.arr-next',
        prevEl: '.arr.arr-prev',
      }"
        :watch-slides-progress="true"
        :modules="[SwiperNavigation, SwiperThumbs]"
    >
      <SwiperSlide class="product-gallery__thumb" :key="0" v-for="image in [product?.featuredImage?.node]">
        <wpImage :img="image"/>
      </SwiperSlide>
      <SwiperSlide
          class="product-gallery__thumb"
          :key="index + 1"
          v-for="(image, index) in product?.galleryImages?.nodes"
      >
        <wpImage :img="image"/>
      </SwiperSlide>
    </Swiper>
    <div class="arr arr-prev">
      <nuxt-img src="/images/icons/right-arrow.png" alt="אייקון תמונה הקודמת"/>
    </div>
    <div class="arr arr-next">
      <nuxt-img src="/images/icons/left-arrow.png" alt="אייקון תמונה הבאה"/>
    </div>
  </div>
</template>
